h1{margin-top: 0;}

h2{
  margin: 5px;
}

h3, h4, p {
  margin: 5px;
}

.App {
  text-align: center;
  background-color: #282c34;
  color: #bbbbbb;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.selected-topics{
  border-style: ridge;
  border-width: 0.5px;
  margin-left: 30%;
  margin-right: 30%;
  padding: 5px;
  border-radius: 10px;
}

.post-header{
  background-color: #414755;
  border-radius: 10px;
  padding: 8px;
}


.post {
  position: relative;
  padding: 10px;
  border-width: 2px;
  width: 50%;
  margin-left: 25%;
  margin-bottom: 5px;
  border-radius: 10px;
  border-style: double;
}


.App-header {
  background-color: #282c34;
  margin-bottom: 20px;
  border-bottom: 2px double grey;
  display: flex;
  flex-direction: row;
  justify-content: top;
  color: white;
}

#login-header{
  margin-top: 0;
}

.App-header button {
  width: fit-content;
  height: fit-content;
  margin-top: 2px;
  margin-left: 10px;
}

a {
  color: aliceblue;
  text-decoration: none;
}


#user-feedback {
  height: 20px;
  background-color: #15390c;
  border: 1px solid #dddddd;
  box-shadow: 2px 2px 10px #dddddd;
  border-radius: 10px;
  padding-top : 10px;
  padding-bottom: 20px;
  text-align: center;
}

button {
  display: inline-block;
  margin: 5px;
  padding: 6px;
  border-radius: 5px;
  background-color: #666666;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  width: auto;
}

button:hover {
  background-color: #555;
}

input[type="text"], textarea {
  padding: 5px;
  border-radius: 5px;
  border: 5px solid #41487a;
  width: 200px;
  transition: border-color 0.2s ease-in-out;
}

input[type="text"]:focus, textarea:focus {
  border-color: #7d809b;
}

select {
  display: inline-block;
  margin: 5px;
  padding: 6px;
  border-radius: 5px;
  background-color: #666666;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  width: auto;
}

